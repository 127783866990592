<template>
  <div class="base">
    <default-top-bar/>
    <default-side-bar/>
    <default-view />
  </div>
</template>

<script>
export default {
  name: "DefaultLayout",
  components: {
    DefaultTopBar: () => import('./TopBar'),
    DefaultSideBar: () => import ('./SideBar'),
    DefaultView: () => import ('./View'),
  }
}
</script>

<style scoped>
.base {
  background-color: #F9F4F7;
  height: 100%;
}
</style>
